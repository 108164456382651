import React, { useState } from "react";

const ContactForm = ({ language }) => {
  const [state, setState] = useState({
    name: "",
    email: "",
    message: "",
    caseType: "", // Added for the dropdown
    phone: "",
  });

  const sendEmail = (event) => {
    event.preventDefault();
    // Formspree endpoint
    const formEndpoint = "https://formspree.io/f/xpzvzqor"; // Replace with your endpoint

    fetch(formEndpoint, {
      method: "POST",
      body: JSON.stringify(state),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          alert(
            language === "english"
              ? "Form successfully submitted"
              : "Formulario enviado con éxito"
          );
          setState({
            name: "",
            email: "",
            message: "",
            caseType: "",
          });
        } else {
          response.json().then((data) => {
            if (Object.hasOwn(data, "errors")) {
              alert(
                language === "english"
                  ? "There was an error submitting the form"
                  : "Hubo un error al enviar el formulario"
              );
            }
          });
        }
      })
      .catch((error) => {
        alert(
          language === "english"
            ? "There was an error submitting the form"
            : "Hubo un error al enviar el formulario"
        );
      });
  };

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className="contact-form-container">
      <form onSubmit={sendEmail} className="contact-form">
        <div className="form-group">
          <label className="form-label">
            {language === "english" ? "Name:" : "Nombre:"}
          </label>
          <input
            required
            type="text"
            name="name"
            value={state.name}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label className="form-label">
            {language === "english" ? "Email:" : "Correo electrónico:"}
          </label>
          <input
            required
            type="email"
            name="email"
            value={state.email}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label className="form-label">
            {language === "english" ? "Phone:" : "Número de teléfono:"}
          </label>
          <input
            required
            type="tel"
            name="phone"
            value={state.phone}
            onChange={handleChange}
            className="form-input"
          />
        </div>
        <div className="form-group">
          <label className="form-label">
            {language === "english" ? "Message:" : "Mensaje:"}
          </label>
          <textarea
            required
            name="message"
            value={state.message}
            onChange={handleChange}
            className="form-textarea"
          ></textarea>
        </div>
        <div className="form-group">
          <label className="form-label">
            {language === "english" ? "Type of Case:" : "Tipo de Caso:"}
          </label>
          <select
            required
            name="caseType"
            value={state.caseType}
            onChange={handleChange}
            className="form-select"
          >
            <option value="">
              {language === "english"
                ? "Select a Case Type"
                : "Seleccione un Tipo de Caso"}
            </option>
            <option value="VAWA">VAWA</option>
            <option value="U-Visa">U-Visa</option>
            <option value="T-Visa">T-Visa</option>
            <option value="Asylum">
              {language === "english" ? "Asylum" : "Asilo"}
            </option>
            <option value="Hardship waiver">
              {language === "english"
                ? "Hardship waiver"
                : "Exención por dificultad"}
            </option>
            <option value="Cancellation of removal">
              {language === "english"
                ? "Cancellation of removal"
                : "Cancelación de deportación"}
            </option>
            <option value="other">
              {language === "english" ? "Other" : "Otro"}
            </option>
          </select>
        </div>
        <button type="submit" className="form-button">
          {language === "english" ? "Send" : "Enviar"}
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
